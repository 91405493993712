import React, { Component } from 'react';
import './Home.css'
import {Container} from 'react-bootstrap';
import ReactTypingEffect from 'react-typing-effect';
class Home extends Component {
    render() {
        return (
        <div className="home-page">
        <Container className="text-container">
            <h1 className="pulsate">Web Ambrosia</h1>
            <ReactTypingEffect className="type-writer"
                text="Coming Soon."
            />
        </Container>
        </div>
        );
    }
}

export default Home;